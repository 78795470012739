import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { roles } from '../constant/roles';

export const operatorRoles = [
  roles.CLEARING_ADMINISTRATOR,
  roles.CLEARING_OPERATOR
];
export const operatorGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const messageService = inject(MessageService);

  const isOperator = authenticationService
    .getUserRoles()
    .some(role => operatorRoles.includes(role));

  if (!isOperator)
    messageService.add({
      severity: 'error',
      summary: 'Forbidden',
      detail: 'You do not have rights to access this page'
    });
  return isOperator;
};
