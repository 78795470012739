import { from, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../service/authentication.service';

function cloneRequestAndAssignAuthorization(req: HttpRequest<unknown>, token: string) {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(AuthenticationService);

  if (authenticationService.isTokenExpired()) {
    return from(authenticationService.updateToken())
      .pipe(
        switchMap(() => from(authenticationService.getAccessToken())), // Get the new token
        switchMap(token => {
          const authReq = cloneRequestAndAssignAuthorization(req, token);
          return next(authReq);
        })
      );
  } else {
    return from(authenticationService.getAccessToken())
      .pipe(
        switchMap(token => {
          const authReq = cloneRequestAndAssignAuthorization(req, token);
          return next(authReq);
        })
      );
  }
};
