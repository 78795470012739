import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { roles } from '../constant/roles';


export const lightGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const messageService = inject(MessageService);

  const isLightUser = authenticationService.getUserRoles().includes(roles.CLEARING_LIGHT);

  if (!isLightUser)
    messageService.add({
      severity: 'error',
      summary: 'Forbidden',
      detail: 'You do not have rights to access this page'
    });

  return isLightUser;
};
