import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';

const CLEARING_ADMINISTRATOR = 'CLEARING_ADMINISTRATOR';

export const adminGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const messageService = inject(MessageService);

  const isAdministrator = authenticationService.getUserRoles().includes(CLEARING_ADMINISTRATOR);

  if (!isAdministrator)
    messageService.add({
      severity: 'error',
      summary: 'Forbidden',
      detail: 'You do not have rights to access this page'
    });

  return isAdministrator;
};
