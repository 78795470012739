import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';


export const authGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);

  const isAuthenticated = authenticationService.isLoggedIn();

  if (!isAuthenticated) {
    await authenticationService.login({
      redirectUri: window.location.href
    });
  } else {
    const [userProfile, accessToken] = await Promise.all([
      authenticationService.getUserProfile(),
      authenticationService.getAccessToken()
    ]);

    authenticationService.updateTokenOnExpire();

    localStorage.setItem('fullName', `${userProfile.firstName} ${userProfile.lastName}`);
    localStorage.setItem('accessToken', accessToken);
  }

  return isAuthenticated;
};
