import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import * as Keycloak from 'keycloak-js';

@Injectable()
export class AuthenticationService {

  constructor(private keycloakService: KeycloakService) {
  }

  get username(): string {
    return this.keycloakService.getUsername();
  }

  getUserRoles() {
    return this.keycloakService.getUserRoles();
  }

  updateTokenOnExpire() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: async (event) => {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          await this.updateToken();
        }
      }
    });
  }

  getUserProfile() {
    return this.keycloakService.loadUserProfile();
  };

  updateToken(minValidity?: number) {
    return this.keycloakService.updateToken(minValidity);
  }

  isTokenExpired(minValidity?: number) {
    return this.keycloakService.isTokenExpired(minValidity);
  }

  login(options?: Keycloak.KeycloakLoginOptions) {
    return this.keycloakService.login(options);
  }

  getAccessToken() {
    return this.keycloakService.getToken();
  }

  isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  async logout(redirectUri: string) {
    await this.keycloakService.logout(redirectUri);
  }
}
