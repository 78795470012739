import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { roles } from '../constant/roles';

export const normalRoles = [
  roles.CLEARING_ADMINISTRATOR,
  roles.CLEARING_OPERATOR,
  roles.CLEARING_VIEWER
];

export const normalGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const messageService = inject(MessageService);

  const isNormalUser = authenticationService
    .getUserRoles()
    .some(role => normalRoles.includes(role));

  if (!isNormalUser)
    messageService.add({
      severity: 'error',
      summary: 'Forbidden',
      detail: 'You do not have rights to access this page',
    });

  return isNormalUser;
};
